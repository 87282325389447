<template>
  <div>
        <p class="mt-2 mb-25 p-0" v-if="script && script.service && script.service.host">
          <small><b class="mr-25">{{$t('integrator.pages.service_view.releases.host')}}: </b> </small>
          <b-badge variant="light-purple" class="integrator-host">{{script.service.host}}</b-badge>
        </p>
        <b-skeleton v-else width="200px" height="20px" class="mb-0 mt-2 mb-25"/>

        <hr class="mt-0">
    <div v-if="fullRelease && fullRelease.script && script.service.groups">


        <h4 class="mb-1">
          <b>{{$t('integrator.pages.service_view.releases.requests')}}</b>
        </h4>
        <transition-group name="list" tag="div">   
          
          <div v-for="group in Object.keys(script.service.groups)" :key="'out-loop-request-'+group">
        <template v-if="script.service.groups[group][operation_type]">
          
          <h5 class="mb-50">
            <feather-icon icon="FolderIcon" size="18" class="mr-50"/>
            <b>{{group}}</b>
          </h5>
          
          <transition-group name="list" tag="div">
              <release-operation
                v-for="operation in Object.values(script.service.groups[group][operation_type])" 
                :key="'operation-' + uuid()"
                :operationInfo="operation"
                />     
              </transition-group>
            </template> 
          </div>
        </transition-group>
          
      </div>
      
    <div v-else class="mt-2">
      <p class="text-secondary mx-1" v-if="!loadingRelease"><small><b>{{$t('integrator.pages.service_view.releases.no_operations')}}</b></small></p>
    </div>
    
    <b-collapse appear :visible="true" v-if="!(script && script.service)" >
      <div v-if="true">
        <div class="d-flex mb-1 ml-1">
          <b-skeleton width="100px" height="23px" class="mr-1"/>
          <b-skeleton width="100px" height="23px"/>
        </div>
        <b-skeleton width="60px" height="20px" class="mb-1"/>
        <b-skeleton height="50px" class=" mb-1"/>
        <b-skeleton height="50px" class=""/>
      </div>
    </b-collapse>
  </div>
</template>

<script>

import {
  BCol,
  BRow, 
  BCard,
  BBadge,
  BIcon,
  BButton,
  BCollapse,
  BModal,
  BPopover,
  BSkeleton,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from "uuid";
import JsonViewer from "vue-json-viewer";
import releaseOperation from '@/views/pages/integrator/components/Releases/ReleaseOperation.vue';


export default {
  components:{
    BCol, 
    BRow, 
    BCard,
    BBadge,
    BIcon,
    BButton,
    BCollapse,
    BModal,
    BPopover,
    BSkeleton,
    JsonViewer,
    releaseOperation,
    BTab,
    BTabs,
  },
  props: {
    releaseInfo: {
      type: [String, Object],
      required: true,
    },
  },
  data() {
    return {
      operation_type: 'requests',
      releases: [ //groups
        [ //releases in group
          {
            expanded: false,
          },
        ]
      ],
      loadingRelease: false,
    }
  },
  computed: {
    ...mapGetters('releaseModule', ['getReleases', 'getReleaseInfo']),
    fullRelease(){
      if (!this.getReleaseInfo(this.releaseInfo.id)) this.fetchRelease();

      return this.getReleaseInfo(this.releaseInfo.id)
    },
    script(){
      if (!this.fullRelease || !this.fullRelease.script) return
      return JSON.parse(this.fullRelease.script)
    },
  },
  mounted () {
    this.init();
  },
  methods: {
    init(){
      this.fetchRelease()
    },
    fetchRelease(){
      this.loadingRelease = true;
      this.$store.dispatch('releaseModule/getReleaseByID', {releaseID: this.releaseInfo.id}).then(() => {
        }).catch((error) => {
          console.error(error);
        }).finally(() => {
          this.loadingRelease = false
        })
    },  
    highlighter(code) {
      return highlight(code, languages.json);
    },
    uuid(){
      return uuidv4()
    }
  },
}

</script>

<style lang="scss" scoped>

</style>

<style lang="scss">

.custom-tab-header{
  color: white !important;
  .nav-link{
    color: rgba(255, 255, 255, 0.7) !important;
  }
}

.custom-tab-header-active{
  padding-bottom: 1px;
  border-bottom: 3px solid var(--purple) !important;
  a.nav-link.active{
    color: var(--purple) !important;
  }
}

.integrator-host{
  height: auto !important;
  word-wrap: normal !important;
  white-space: normal;
  text-align: left;
  height: fit-content;
}
</style>