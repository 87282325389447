<template>
  <main>

    <!-- HEADER =============================================================== -->
    <header>
      <router-link v-if="service && service.organization" :to="`../${service.id}/edit`" class="edit-service-btn">
        <b-button variant="outline-purple">
          <b-icon icon="gear" scale="1.3" class="mr-1"/>
          {{$t('integrator.pages.service_view.header.edit_service')}}
        </b-button>
      </router-link>
      
      <div class="header d-flex justify-content-between my-3" :style="`--service-header-bg-img: url(${service.image_uri})`">
        <template v-if="service && service.organization">
        <div class="ml-2 d-flex">        
          <div class="avatar-container">          
            <avatar
              class="avatar"
              :serviceID="service.id"
              :orgID="service.organization.id"
              serviceGetter="serviceModule/getIntegratorServiceByID"
              :size="150"
            />
          </div> 
          <div class="mt-75 ml-2">
            <service-badges
              :serviceID="service.id"
              :orgID="service.organization.id"
              :categories="service.integrator_category"
              blockEdit
              @saved="$emit('saved')"
            />

            <h1 class="mt-1">{{service.name}}</h1>
            
            <h3 class="mt-1 text-secondary">
              <router-link :to="`/integrator/organization/${service.organization.id}/services`" class="text-secondary">
                <b>{{service.organization.name}}</b>
              </router-link>
            </h3>
            <div class="d-flex">
              <b-modal id="toggle-install-modal" centered size="sm" @hide="((e) =>{ if (installModalInfo.loading) e.preventDefault()})">
                <template #modal-title>
                  <b>
                    <span v-if="selectedRelease.is_installed">{{$t('integrator.pages.service_view.installation_modal.confirm_uninstall')}}</span>
                    <span v-else>{{$t('integrator.pages.service_view.installation_modal.confirm_install')}}</span>
                  </b>
                  
                </template>
                
                <div class="" v-if="selectedRelease">
                  <p class="p-0 m-0 mb-50"> 
                    <span v-if="selectedRelease.is_installed">{{$t('integrator.pages.service_view.installation_modal.uninstalling')}} </span>
                    <span v-else>{{$t('integrator.pages.service_view.installation_modal.installing')}} </span>
                    
                    <b>{{service.name}}</b>,</p>
                  
                  <p class="p-0 m-0">{{$t('integrator.pages.service_view.installation_modal.with_release')}} <b>{{selectedRelease.label}}</b></p>
                </div>

                <template #modal-footer>
                  <div>
                    <b-button variant="secondary" class="mr-1" @click="$bvModal.hide('toggle-install-modal')" :disabled="installModalInfo.loading">{{$t('integrator.pages.service_view.installation_modal.cancel')}}</b-button>
                    <b-button variant="purple" @click="toggleInstall()">
                      <span v-if="!installModalInfo.loading">{{$t('integrator.pages.service_view.installation_modal.confirm')}}</span>
                      <b-spinner v-else small/>
                    </b-button>
                  </div>
                </template>
              </b-modal>
              
              <b-button v-if="service.installed_releases.length" variant="outline-purple" class="mt-2" @click="$refs['releases-card'].scrollIntoView({behavior: 'smooth'})"> 
                <b-icon icon="download" scale="1" class="mr-1"/>
                {{$t('integrator.pages.service_view.header.installed')}}
              </b-button>
              
              <b-button v-else variant="purple" class="mt-2" @click="$refs['releases-card'].scrollIntoView({behavior: 'smooth'})"> 
                <b-icon icon="download" scale="1" class="mr-1"/>
                {{$t('integrator.pages.service_view.header.install')}}
              </b-button>

              <div class="mt-2 ml-1">
                <b-overlay id="favorite-overlay" ref="favorite-overlay" :show="activeOverlays.includes('favorite-overlay')" variant="none">
                  <b-button v-if="service.favorite" variant="none" @click="toggleFavorite()">
                    <b-icon icon="bookmark-fill" scale="1" class=""/>
                    {{$t('integrator.pages.service_view.header.favorited')}}
                  </b-button>
                  <b-button v-else variant="none" class=""  @click="toggleFavorite()">
                    <b-icon icon="bookmark" scale="1" class=""/>
                    {{$t('integrator.pages.service_view.header.add_favorite')}}
                  </b-button>
                </b-overlay>
              </div>

            </div>
          </div>
        </div>
        <div class="align-self-center d-flex align-items-center text-right mr-3">
          <div class="mt-1">
            <p class="p-0 m-0 text-center"><b>{{service.average_rating}}</b> <b-icon icon="star" shift-v="2" class="mx-50"/></p>
            <p class="text-secondary"><b>{{$t('integrator.pages.service_view.header.avg_rating')}}</b></p>
          </div>
          <div class="divider"></div>
          <div class="mt-1">
            <p class="p-0 m-0 text-center"><b>{{getDownloadCount}}</b> <b-icon icon="download" shift-v="2" class="mx-50"/></p>
            <p class="text-secondary"><b>{{$t('integrator.pages.service_view.header.downloads')}}</b></p>
          </div>
        </div>

        </template>

        <div class="px-2 d-flex mb-2" v-else>
          <b-skeleton width="190px" height="190px" class="rounded" animation="wave"/>
          <div class="py-50 ml-2">
            <b-skeleton width="50px" height="20px" animation="wave"/>
            <b-skeleton width="200px" height="30px" animation="wave"/>
            <b-skeleton width="150px" height="20px" animation="wave"/>
            <div class="d-flex mt-2">
              <b-skeleton width="150px" height="40px" animation="wave" class="mt-1"/>
              <b-skeleton width="150px" height="40px" animation="wave" class="mt-1 ml-2"/>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- END OF HEADER ======================================================== -->
    
    <template v-if="!(service && service.organization)">
      <b-skeleton width="100%" height="50px" animation="fade" class="mt-1"/>
      <b-skeleton width="100%" height="500px" animation="fade" class="mt-1"/>
    </template>

    <template v-else>
     <b-card text-variant="white" header-class="px-0 pb-0" v-if="service.short_description"> <!-- DESCRIPTION:  -->
      <template #header>
        <h3 class="px-2">{{$t('integrator.pages.service_view.description.title')}}</h3>
        <hr class="w-100">
      </template>
          {{service.short_description}}
    </b-card>
    
    <!-- READ ME:  -->
    <readme-edit
      v-if="service.readme" 
      v-model="service.readme" 
      readOnly
      :cardBodyClass="readmeExpanded ? 'readme-preview expanded' : 'readme-preview'"
    >
      <template #footer>
        <div align="center" class="mt-75">
          <span class="expand-readme-btn d-block">
            <b-button @click="readmeExpanded = !readmeExpanded" variant="outline-light" class="px-3">
              <span v-if="!readmeExpanded">
              <b-icon icon="chevron-down" scale="0.8" class="mr-1" />
              {{$t('integrator.pages.service_view.readme.expand')}}
              </span>
              <span v-else>
                <b-icon icon="chevron-up" scale="0.8" class="mr-1" />
                {{$t('integrator.pages.service_view.readme.hide')}}
              </span>
            </b-button>
          </span>
        </div>
      </template>
    </readme-edit>



     <!-- RELEASES: ============-->
    <b-card text-variant="white" header-class="px-0 pb-0" v-if="service" ref="releases-card">
      <template #header>
        <h3 class="px-2">{{$t('integrator.pages.service_view.releases.title')}}</h3>
        <hr class="w-100">
      </template>
      
      <h5 class="mt-1">{{$t('integrator.pages.service_view.releases.release')}}:</h5>

      <!-- {{releases}} -->

      <b-button class="float-right" variant="purple" @click="$bvModal.show('toggle-install-modal')" :disabled="!selectedRelease" :title="!selectedRelease ? $t('integrator.pages.service_view.releases.no_release_install'): ''">
        <span v-if="selectedRelease && (selectedRelease.is_installed)">
          <b-icon icon="trash" scale="1.1" class="mr-1"/>
          {{$t('integrator.pages.service_view.releases.uninstall')}}
        </span>
        <span v-else>
          <b-icon icon="download" scale="1.1" class="mr-1"/>
          {{$t('integrator.pages.service_view.releases.install')}}
        </span>
     
      </b-button>

      <v-select class="w-50 mb-1" :options="sortedReleases" v-model="selectedRelease" :clearable="false" v-if="sortedReleases"> 

        <template #option="{label, enum_release_tag, is_installed}">
          <div>
            <span>{{label}}</span>
            <small>
            <b-badge pill class="mx-1" :variant="getReleaseTagInfo(enum_release_tag.id).variant">{{$t(enum_release_tag.label)}}</b-badge></small>

            <span class="float-right text-muted" v-if="is_installed"><small>{{$t('integrator.pages.service_view.releases.installed')}}</small></span>
          </div>
        </template>

        <template #selected-option="{ label, enum_release_tag }">
          <div>
            <span>{{label}}</span>
            <small>
            <b-badge pill class="mx-1" :variant="getReleaseTagInfo(enum_release_tag.id).variant">{{$t(enum_release_tag.label)}}</b-badge></small>
          </div>
        </template>

        <template #no-options>
          <span>{{$t('integrator.pages.service_view.releases.no_releases')}}</span>
        </template>

      </v-select>
      <div v-else>
        <b-skeleton height="40px" width="50%"/>
      </div>
      
      <show-release 
        v-if="selectedRelease"
        :releaseInfo="selectedRelease"
      />

    </b-card>
    <!-- ==================== -->

    <b-card text-variant="white" header-class="px-0 pb-0"  ref="review-form"> <!-- REVIEWS:  -->
      <template #header>
        <h3 class="px-2">{{$t('integrator.pages.service_view.reviews.title')}}</h3>
        <hr class="w-100">
      </template>

      <div class="bg-dark-blue p-2 rounded" id="review-form" v-if="service.installed_releases.length">
        <b-overlay :show="activeOverlays.includes('review-submit-overlay')" rounded variant="none">
          <span class="text-secondary mb-2 d-block"><b>{{$t('integrator.pages.service_view.reviews.leave_a_review')}} </b></span>
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <b-avatar size="50" class="custom-avatar mr-1" ></b-avatar>
              <div>
                <p class="m-0" v-if="getLoggedUser && getLoggedUser.username"><b>{{getLoggedUser.username}}</b></p>
                <p class="m-0" v-if="organizationInfo && organizationInfo.name">{{organizationInfo.name}}</p>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <div>
              
                <b-form-rating 
                  v-model="reviewForm.stars" 
                  variant="favorite" 
                  icon-empty="star-fill" 
                  inline 
                >
                  <template #icon-empty>
                    <b-icon icon="star-fill" variant="secondary"/>
                  </template>
                </b-form-rating> 
                <p class="p-0 m-0 mx-1 text-secondary text-right"><small>{{$t('integrator.pages.service_view.reviews.rating')}} <b class="monospace">{{reviewForm.stars}}/5</b></small></p>
              </div>

            </div>
          </div>
          
          <div class="mt-1">
            <span class="text-secondary"><small>{{$t('integrator.pages.service_view.reviews.your_review')}}</small></span>
            <b-form-textarea 
              size="lg"
              class="custom-textarea" 
              v-model="reviewForm.text"
            />
          </div>

          <div class="d-flex justify-content-between">
            <div class="w-50">
              <small>
                <span class="text-secondary">{{$t('integrator.pages.service_view.reviews.release')}}</span>
                <v-select class="" :options="installedReleases" v-model="selectedCommentRelease" :clearable="false"> 

                  <template #option="{label, enum_release_tag}">
                    <div>
                      <span>{{label}}</span>
                      <small>
                      <b-badge pill class="mx-1" :variant="getReleaseTagInfo(enum_release_tag.id).variant">{{$t(enum_release_tag.label)}}</b-badge></small>
                    </div>
                  </template>

                  <template #no-options>
                    <span>{{$t('integrator.pages.service_view.reviews.no_releases')}}</span>
                  </template>

                </v-select>
              </small>
            </div>

            <div align="right" class="mt-1 w-50">
              <b-button variant="purple" @click="submitReview()" :disabled=" !(reviewForm.text !='' && reviewForm.stars!=0 && selectedCommentRelease)">
                <b-icon icon="cursor" scale="1"/>
                {{$t('integrator.pages.service_view.reviews.publish')}}
              </b-button>
            </div>
          </div>
        </b-overlay>
      </div>

      <!-- REVIEWS V-FOR =================== -->
      <template v-if="reviewsLoaded">
        <TransitionGroup name="list" tag="div">
          <div class="mt-2" v-for="comment in limitedServiceComments" :key="'service-comment-'+comment.id">

            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex">
                <b-avatar size="50" class="mr-1" :src="comment.user.gravatar_url"></b-avatar>
                <div>
                  <p class="m-0"><b>{{comment.user.username}}</b></p>
                  <p class="m-0">{{comment.organization.name}}</p>
                </div>
              </div>
              <div class="d-flex text-secondary">
                <span>{{getReviewDate(comment.created_at)}}</span>
                <span class="mx-1">|</span>
                <span>{{$t('integrator.pages.service_view.reviews.release')}} <b>{{comment.integrator_service_release.label}}</b></span>
                <span class="mx-1">|</span>
                <div>
                  <b-icon icon="star-fill" :variant="i <= comment.stars ? 'favorite' : 'secondary' " class="mr-25" v-for="i in 5" :key="uuidv4()"/>
                </div> 
              </div>
            </div>
            <div class="mt-75">
              <p class="px-1 text-justify spaced-lines">{{comment.commentary}}</p>
            </div>
            <div>
              <span class="mx-2">
                <b-button variant="none" class="p-0">
                  <feather-icon
                    icon="ThumbsUpIcon"
                    size="22"
                    class="mx-50 text-white"
                  />
                  </b-button>
                  {{comment.upvotes}}
              </span>
              <span class="mx2">
                <b-button variant="none" class="p-0">
                  <feather-icon
                    icon="ThumbsDownIcon"
                    size="22"
                    class="mx-50 text-white"
                  />
                </b-button>
                {{comment.downvotes}}
              </span>
              </div>
            <hr class="mt-2">
          </div>
        </TransitionGroup>

        <div align="center" v-if="serviceComments.length > 3">
          <b-button variant="outline-light" @click="reviewsExpanded = !reviewsExpanded">
            <span v-if="!reviewsExpanded">
              <b-icon icon="chevron-down" scale="0.8" class="mr-1"/>
              {{$t('integrator.pages.service_view.reviews.show_more')}}
            </span>
            <span v-else>
              <b-icon icon="chevron-up" scale="0.8" class="mr-1"/>
              {{$t('integrator.pages.service_view.reviews.hide')}}
            </span>
          </b-button>
        </div>

        <template v-if="serviceComments.length==0">
          <p class="text-secondary text-center mt-2">{{$t('integrator.pages.service_view.reviews.no_reviews')}}</p>
        </template>

        
      </template>
      <template v-else>
        <div align="center" class="my-2">
          <p class="text-center text-secondary mb-50">{{$t('integrator.pages.service_view.reviews.loading')}}</p>
          <b-spinner variant="secondary"/>
        </div>
      </template>
      <!-- END OF REVIEWS V-FOR ============== -->
      
    </b-card>

    </template>
  </main>
  
</template>

<script>

import {
  BContainer,
  BCol,
  BRow,
  BCard,
  BCardHeader,
  BCardBody,
  BAvatar,
  BIcon,
  BTab,
  BTabs,
  BButton,
  BCardText,
  BCardGroup,
  BFormRating,
  BForm,
  BFormGroup,
  BFormTextarea,
  BSkeleton,
  BModal,
  BOverlay,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import VSelect from "vue-select";
import DefaultChanger from '@/layouts/components/DefaultChanger.vue';
import Avatar from '@/views/pages/integrator/components/General/Avatar.vue';
import ServiceBadges from '@/views/pages/integrator/components/General/Badges.vue'
import { mapGetters } from 'vuex'
import ReadmeEdit from "@/views/pages/integrator/components/EditService/ReadmeEdit.vue";
import { makeToast } from '@/layouts/components/Popups';
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import ShowRelease from '@/views/pages/integrator/components/Releases/ShowRelease.vue';
import ReleaseTags from "@/custom/class/Enum/ReleaseTags.js";

export default {
  components: {
    BContainer,
    BCol,
    BRow,
    BCard,
    BCardHeader,
    BCardBody,
    BAvatar,
    BIcon,
    BTab,
    BTabs,
    BButton,
    DefaultChanger,
    Avatar,
    ServiceBadges,
    BCardText,
    ReadmeEdit,
    BCardGroup,
    BFormRating,
    BForm,
    BFormGroup,
    BFormTextarea,
    BSkeleton,
    BModal,
    BOverlay,
    BSpinner,
    ShowRelease,
    VSelect,
    BBadge,
  },
  data() {
    return {
      readmeExpanded: false,
      reviewsExpanded: false,
      reviewsLoaded: false,
      reviewForm: {
        stars: 0,
        text: '',
      },
      activeOverlays: [],
      uuidv4,
      selectedRelease: null,
      selectedCommentRelease: null,
      installModalInfo: {
        loading: false,
      },
      organizationInfo: null,
    }

  },
  computed: {
    ...mapGetters('serviceModule', ['getIntegratorServiceByID', 'getServiceInfo','insertReleaseComment','getServiceComments']),
    ...mapGetters('releaseModule', ['getReleases']),
    ...mapGetters(['getLoggedUser']),
    service() {
      return this.getServiceInfo
    },
    releases() {
      return this.getReleases(this.service.id)
		},
    serviceIdentifier(){
      return this.$route.params.serviceIdentifier;
    },
    organizationID(){
      return localStorage.getItem('selectedOrganization');
    },
    getDownloadCount(){
      let r = this.service.downloads_counter
      if (r>1000){
        r = Math.floor(r / 1000) + 'K'
      }
      return r;
    },
    serviceComments(){
      return this.getServiceComments;
    },
    limitedServiceComments(){
      let r = this.serviceComments
      if (r.length > 3 && !this.reviewsExpanded){
        r = r.slice(0, 3);
      }
      return r;
    },
    sortedReleases(){
      if (!this.releases) return;
      let releases = structuredClone(this.releases)
      
      //this sorts the releases by date
      releases.sort(function(a,b){
        return new Date(b.created_at) - new Date(a.created_at);
      });
      let orderedTagIDs = [1,3,2,4];

      let filtered = [];
      orderedTagIDs.forEach(tagID => {
        filtered.push(...releases.filter(release => release.enum_release_tag.id == tagID))        
      });
      
      filtered.forEach(el => {
        if (this.service.installed_releases.includes(el.id)){
          el.is_installed = true;
        }
      });

      return filtered;
    },
    installedReleases(){
      if (!this.sortedReleases || !this.service || !this.service.installed_releases.length) return;
      let releases = this.sortedReleases;
      let installed = releases.filter(rel => rel.is_installed == true);
      
      return installed  
    }
  },

  mounted () {
    this.init();
  },
  methods: {
    init() {
        this.$store.dispatch('serviceModule/clearIntegratorServiceInfo'); //if you don't clear the store serviceInfo it may display the wrong data until the request is completed
        this.getServiceByIdentifier()
        this.getServicesByOrganization()//this is necessery for avatar display
      },
      getServicesByOrganization() {
        this.$store.dispatch('serviceModule/fetchIntegratorServicesByOrganization', {organizationID: this.organizationID, currentOrgID: this.organizationID}).then(() => {
        }).catch((err) => {
          console.error(err)
          makeToast({
            title: '',
            text: 'error',
            variant: 'danger',
            icon: 'XIcon'
          })
        })
      },
      submitReview(){
        const flag = "review-submit-overlay"
        this.activeOverlays.push(flag)

        const data = {
          "commentary": this.reviewForm.text,
          "stars": this.reviewForm.stars,
          "integrator_service_release_id": this.selectedCommentRelease.id,
          "organization_id": parseInt(this.organizationID),
        }
        this.$store.dispatch('serviceModule/insertServiceComment', {serviceID: 15, data: data})
          .then(() => {
            this.reviewForm.stars = 0
            this.reviewForm.text = ""
          }).catch((error) => {
            console.error(JSON.stringify(error))
        }).finally(()=>{
          this.activeOverlays.splice(this.activeOverlays.indexOf(flag), 1);
        })
      },
      getServiceByIdentifier() {
        this.$store.dispatch('serviceModule/fetchServiceByIdentifier', {organizationID: this.organizationID, identifier:this.serviceIdentifier})
          .then(() => {
            this.getServiceReviews();
            this.$nextTick(()=>{
              this.getReleasesByService();
            })
            this.checkUrlHash();
          }).catch((err) => {
            console.error(err)
            if (err.message == 'Request failed with status code 400'){
              this.$router.push('/error-404')
            }
          })
      },
      getServiceReviews(){
        this.$store.dispatch('serviceModule/fetchServiceComments', {serviceID: this.service.id})
          .then(() => {
            this.reviewsLoaded = true;
          }).catch((error) => {
            console.error(error)
          })
      },  
      getReleasesByService(){
        if (Array.isArray(this.installedReleases)){
          if (this.sortedReleases && this.sortedReleases.length){
            this.selectedRelease = this.sortedReleases[0]
            this.selectedCommentRelease = this.installedReleases[0]
          }
        }

        if (!this.releases) {
          this.$store.dispatch('releaseModule/getReleasesByService', {serviceID: this.service.id}).then(() =>{
            if (this.sortedReleases.length) this.selectedRelease = this.sortedReleases[0]
            
          }).catch((err)=>{
            console.error(err)
          });
        }
		  },
      toggleInstall(){
        this.installModalInfo.loading = true;

        if(!this.selectedRelease.is_installed){
          this.$store.dispatch('serviceModule/installService', {organizationID:this.organizationID, releaseID: this.selectedRelease.id})
            .then(() => {
              makeToast({
                title: 'Success!',
                text: `Release ${this.selectedRelease.label} successfully installed.` ,
                variant: 'primary',
                icon: 'CheckIcon'
              })
            }).catch((error) => { 
              console.error(error)
            }).finally(() => {
              this.installModalInfo.loading = false;
              this.$bvModal.hide('toggle-install-modal');
              let id = this.selectedRelease.id
              this.selectedRelease = this.sortedReleases.filter(rel => rel.id == id)[0]
            })          
        } else {
          this.$store.dispatch('serviceModule/uninstallService', {organizationID:this.organizationID, releaseID: this.selectedRelease.id})
            .then(() => {
              makeToast({
                title: 'Success!',
                text: `Release ${this.selectedRelease.label} successfully uninstalled.` ,
                variant: 'primary',
                icon: 'CheckIcon'
              })
            }).catch((error) => { 
              console.error(error)
            }).finally(() => {
              this.installModalInfo.loading = false;
              this.$bvModal.hide('toggle-install-modal');
              let id = this.selectedRelease.id
              this.selectedRelease = this.sortedReleases.filter(rel => rel.id == id)[0]
            }) 
        }

        
      },
      toggleFavorite(){
        const flag = 'favorite-overlay'
        this.activeOverlays.push(flag)

        let operation;

        if (this.service.favorite){
          operation = 'remove'
        } else {
          operation = 'put'
        }

        this.$store.dispatch('serviceModule/toggleFavorite', {organizationID: this.organizationID, integrator_service:this.service.id, operation: operation})
          .then(() => {
            
          }).catch((error) => { 
            console.error(error)
          }).finally(() => {
            this.activeOverlays.splice(this.activeOverlays.indexOf(flag), 1);
          })
      },
      getReviewDate(date_raw){
        
        return moment(date_raw).format('MMMM D, YYYY - h:mm a');
      },
      getReleaseTagInfo(tagID){
        return new ReleaseTags().items.find(el => el.id == tagID)
      },
      checkUrlHash(){
        if (location.hash){
          if (location.hash=="#review-form"){
            this.$refs['review-form'].scrollIntoView({behavior: 'smooth'})
          }
        }
      }
  }
};
</script>



<style lang="scss">

.btn.disabled{
  cursor: not-allowed;
}

.monospace{
  font-family: monospace !important;
}
.custom-textarea{
  min-height: 130px;
}

.readme-preview{
  max-height: 200px !important;
  overflow: auto;
  transition: all 0.5s !important;
}

.readme-preview.expanded{
  max-height: none !important;
}

.readme-preview .editor-preview{
  transition: all 0.5s !important;
  height: fit-content !important;
  overflow: hidden !important;
}
.readme-preview .CodeMirror-scroll{
  transition: all 0.5s !important;
  height: fit-content !important;
  overflow: hidden !important;
  visibility: hidden !important;
}

</style>

<style lang="scss" scoped>
.list-enter-active{
  transition: all 1s ease !important
}
.list-leave-active{
  opacity: 0 !important;
}
.list-enter-from, .list-leave-to {
  opacity: 0 !important;
}

.spaced-lines{
  line-height: 25px;
}

main{
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
}

.edit-service-btn{
  position: absolute;
  right: 0;
}

.avatar-container{
  padding: 5px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  background-color: #1d2439;
  height: fit-content;
  cursor: unset;
  *{
    cursor: unset;
  }
}

.header{
  padding-top: 20vh;
  background-image: var(--service-header-bg-img);
  background-position: center;  
  background-size: contain;

  background-repeat: no-repeat;
  box-shadow: inset 0 0 25vh 20vh  #161d31;
}

.divider{
  width: 1px;
  background-color: #82868b;
  height: 30px;
  margin: 0 15px;
}

.expand-readme-btn{
  margin-top: -35px;

  background-color: #283046;
  width: fit-content;
  box-shadow: 0 0 15px #283046;
}

.custom-avatar{
  box-shadow: inset 0 0 0 1px var(--secondary) !important;
}
</style>  